const defaultLanguage = process.env.LOCALE || 'es-CO'

const defaultOption = 'pide-tu-muestra'

const freeSampleUrl =
	{
		'es-CO': 'muestra-gratis',
		default: defaultOption,
	}[defaultLanguage] || defaultOption

console.log(
	'🚀 ~ file: freeSampleUrl.js ~ line 7 ~ freeSampleUrl',
	freeSampleUrl
)

export default freeSampleUrl
