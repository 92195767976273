import React, { useState } from 'react'
import ProductDetailSection from '../../../shared/components/molecules/productDetailSection/productDetailSection'
import useCrudFreeSample from '../../../shared/hooks/useCrudFreeSample'
import DestinyFreeSample from '../../molecules/destinyFreeSample/destiny-free-sample'
import BackButton from '../../../shared/components/atoms/back-button/back-button'
import './content-free-sample-product.scss'
import useModalGeneric from '../../../shared/hooks/useModalGeneric'
import useModalAuth from '../../../shared/hooks/useModalAuth'
import ShoppingCar from '../../molecules/shoppingCar/shopping-car'
import useSessionState from '../../../shared/hooks/useSessionState'
import useCoverageState from '../../../shared/hooks/useCoverageState'
import ModalAuth from '../../../shared/components/molecules/modalAuth/modal-auth'
import ModalAlert from '../../molecules/modalAlert/modalAlert'
import ModalSamplesAlert from '../../molecules/modalSamplesAlert/modalSamplesAlert'
import ModalCoverage from '../../molecules/modalCoverage/modalCoverage'
import { localStorage } from '../../../shared/state/utils/storage'
import SamplesService from '../../../shared/services/samples'
import freeSampleUrl from '../../../shared/utils/freeSampleUrl'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'

const ContentFreeSampleProduct = ({
	parentSlug,
	product,
	tallaNecesitada,
	labelButton,
	labelMuestra,
	recipientS,
	productosSeleccionados,
	lblSolicitarMuestra,
	samplesLength,
	citys,
	nameButton,
}) => {
	const [size, setSize] = useState(null)
	const [desSample, setDesSample] = useState(null)
	const [samples, addSample, removeSample, response] = useCrudFreeSample()
	const { homeLink } = useHomeLinkMode()

	// eslint-disable-next-line no-unused-vars
	const [isCityCoverage, validateCoverage] = useCoverageState(citys)

	const { isLoggedUser } = useSessionState()
	const [animation, setAnimation] = useState('')
	const [infoAlert, setInfoAlert] = useState({ text: '' })
	const [currentUserTypeId, setcurrentUserTypeId] = useState('')

	const [isModalAuth, validateSamples, hideModalAuth] = useModalAuth()

	// eslint-disable-next-line no-unused-vars
	const [showingModalAlert, showModalAlert, hideModalAlert] = useModalGeneric(
		false
	)

	const [
		showingModalCoverage,
		showModalCoverage,
		hideModalCoverage,
	] = useModalGeneric(false)

	const [
		isModalCustomCoverage,
		showModalCustomCoverage,
		hideModalCustomCoverage,
	] = useModalGeneric(false)

	const [
		showingModalSamplesAlert,
		showModalSamplesAlert,
		hideModalSamplesAlert,
	] = useModalGeneric(false)

	const validatesize = () => {
		if (product.talla && !size) {
			setAnimation('f-destiny-content--animation')
			setInfoAlert({
				text: 'Debes seleccionar una talla y un perfil para continuar',
			})
		} else {
			setInfoAlert({ text: '' })
		}
		setTimeout(() => {
			setAnimation('')
		}, 1000)
		if (product.talla && size) {
			_addSample()
		} else if (!product.talla) {
			_addSample()
		}
	}

	const validateUserSelectionBeforeAddSample = () => {
		if (!currentUserTypeId) {
			setAnimation('f-destiny-content--animation')
			setTimeout(() => {
				setAnimation('')
			}, 1000)
			product.talla
				? setInfoAlert({
						text: 'Debes seleccionar una talla y un perfil para continuar',
				  })
				: setInfoAlert({
						text: 'Debes seleccionar un perfil para continuar',
				  })
		} else {
			validatesize()
		}
	}
	const _addSample = () => {
		const haveCustomCoverage =
			product.coberturaPersonalizada !== null &&
			product.coberturaPersonalizada?.length >= 1
		if (samples.length < samplesLength) {
			if (
				isLoggedUser &&
				haveCustomCoverage &&
				!validateCoverage(product.coberturaPersonalizada)
			) {
				showModalCustomCoverage()
			} else {
				addSample({
					id: product.codigoDeProducto,
					img: product.imagenMuestraPrevisualizacion.fluid.src,
					name: product.nombreProducto,
					customCoverage: product.coberturaPersonalizada,
					destiny: desSample,
					categoria: product.categorias[0].titulo||'',
					variant: product.linea[0]? product.linea[0]?.nombreDeEtiqueta : undefined,
					drip_degree: product.tipoDeIncontinencia? product.tipoDeIncontinencia[0].nombreDeEtiqueta||'' : '',
					size,
				})

				const payload = localStorage.getItem('samples').map( (s) => {
					return {
						idProductContent: s.id,
						size: s.size,
					}
				})
				SamplesService.addUsersCartForgotten(
					error => {
						console.log('error', error)
						window.location.href = `/${freeSampleUrl}/`
					},
					() => {
						localStorage.setItem('userAdded', true)
						window.location.href = `/${freeSampleUrl}/`
					},
					payload
				)
			}
		} else {
			showModalSamplesAlert()
		}
	}
	return (
		<div className='f-container-product'>
			<ProductDetailSection
				product={product}
				backButton={
					<BackButton
						href={'/' + parentSlug}
						title='VOLVER A CATÁLOGO DE MUESTRAS'
					/>
				}
				codigoDeProducto={product.codigoDeProducto}
				isFreeSamples={true}
				idProduct={product.id}
				title={product.nombreProducto}
				descripcion={product.descripcionProducto}
				numeroGotas={product.numeroDeGotas}
				numeroGotasLlenas={product.numeroDeGotasLlenas}
				imagenProducto={product.imagenMuestraCompleta}
				nivelIncontinencia={product.nivelIncontinencia}
				slug={product.slug.split('/')[2]}
				sizes={product.talla}
				selectSize={setSize}
				sizeSelected={size}
				lbtalla={tallaNecesitada}
				samples={samples.filter(
					sample => sample.id === product.codigoDeProducto
				)}
				samplesSent={response ? response.status === 200 && response.data : []}
				atributes={product.sellos}
				nameButton={nameButton}
			/>
			<DestinyFreeSample
				product={product}
				selectDestinySample={setDesSample}
				canAdd={
					desSample &&
					(!product.talla || (product.talla && size))
				}
				handleAction={validateUserSelectionBeforeAddSample}
				labelButton={labelButton}
				label={labelMuestra}
				recipientS={recipientS}
				talla={product.talla}
				animation={animation}
				currentUserTypeId={currentUserTypeId}
				setcurrentUserTypeId={setcurrentUserTypeId}
				infoAlert={infoAlert}
			/>
			{samples && samples.length > 0 && (
				<ShoppingCar
					removeSample={removeSample}
					samples={samples}
					jsonProductSelec={productosSeleccionados.json}
					actionCar={validateSamples}
					labelButton={lblSolicitarMuestra}
				/>
			)}
			{!showingModalCoverage && isModalCustomCoverage && (
				<ModalCoverage
					hideAction={hideModalCustomCoverage}
					actionAlert={hideModalCustomCoverage}
					description='No tenemos cobertura en tu zona para que recibas este producto. Debes ingresar una dirección donde nuestra marca tenga cobertura.'
					buttonLabel='ACEPTAR'
				/>
			)}
			{showingModalCoverage && (
				<ModalCoverage
					hideAction={hideModalCoverage}
					actionAlert={() =>
						(window.location.href = homeLink + 'mi-cuenta/datos-personales')
					}
				/>
			)}
			{showingModalSamplesAlert &&
				!showingModalCoverage &&
				!isModalCustomCoverage && (
					<ModalSamplesAlert
						hideAction={hideModalSamplesAlert}
						actionAlert={() =>
							(window.location.href = homeLink + 'mi-cuenta/datos-personales')
						}
					/>
				)}
			{isModalAuth && (
				<ModalAuth
					hideAction={hideModalAuth}
					validateCoverage={validateCoverage}
					showModalCoverage={showModalCoverage}
				/>
			)}
			{showingModalAlert && (
				<ModalAlert hideAction={hideModalAlert} actionAlert={validateSamples} />
			)}
		</div>
	)
}

export default ContentFreeSampleProduct
