import React from 'react'
import Icon from '../Icon/Icon'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'

import './back-button.scss'

const BackButton = ({ href, title }) => {
	return (
		<a href={href} className='f-back-button'>
			<Icon icon='icon-arrow-left white' size='3' tagtype='span' />
			<p>{convertText(title || 'VOLVER AL INICIO')} </p>
		</a>
	)
}

export default BackButton
