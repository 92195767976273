import { graphql } from 'gatsby'
import React from 'react'
import ContentFreeSampleProduct from '../components/organisms/content-free-sample-product/content-free-sample-product'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useLocaleMode from '../shared/hooks/useLocaleMode'
import useMobileMode from '../shared/hooks/useMobileMode'

const FreeSampleProductTemplate = ({ pageContext, data }) => {
	const { locale } = useLocaleMode()
	const samplesLength = Number(
		data.allContentfulTenaHome.nodes[0].numeroDeMuestrasDiarias
	)
	const citysInit = data.allContentfulTenaPaises.nodes.filter(node =>
		locale.includes(node.codigoDeDosLetras)
	)[0]
	const citys =
		citysInit &&
		(locale === 'es-PR' ? citysInit.departamentos : citysInit.ciudades)
	const { isMobile, currentPage } = useMobileMode()

	return (
		<Layout title={pageContext.metaTitulo} isMobile={isMobile} currentPage={currentPage} white={true}>
			<SEO
				title={pageContext.metaTitulo}
				description={pageContext.metaDescripcion.metaDescripcion}
				lang={locale}
			/>

			<ContentFreeSampleProduct
				parentSlug={pageContext.parentSlug}
				product={pageContext.product}
				tallaNecesitada={pageContext.tallaNecesitada}
				labelButton={pageContext.labelButtonMuestra}
				labelMuestra={pageContext.laMuestraEsPara}
				recipientS={pageContext.destinatariosDeMuestra}
				productosSeleccionados={pageContext.productosSeleccionados}
				lblSolicitarMuestra={pageContext.lblSolicitarMuestra}
				samplesLength={samplesLength}
				citys={citys}
				nameButton='SOLICITAR MUESTRAS GRATIS'
			/>
		</Layout>
	)
}
export default FreeSampleProductTemplate
export const query = graphql`
	query GET_CONTENT_FREE_SAMPLES_TEMPLATE($language: String!) {
		allContentfulTenaPaises(filter: { node_locale: { eq: "es-CO" } }) {
			nodes {
				codigoDeDosLetras
				titulo
				node_locale
				codigoDelPais
				ciudades {
					codigo
					id
					nombre
					codigoDepartamento
					coverage
				}
				departamentos {
					codigo
					nombre
					coverage
				}
			}
		}
		allContentfulTenaHome(filter: { node_locale: { eq: $language } }) {
			nodes {
				numeroDeMuestrasDiarias
			}
		}
	}
`
